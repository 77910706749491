<template>
  <l-map
    :zoom="zoom"
    :center="center"
    :options="mapOptions"
  >
    <l-tile-layer
      :url="url"
      :attribution="attribution"
    />
    <l-control>
      <img src="../../../assets/images/schedule/kmtc_logo.gif" style="width: 150px;">
    </l-control>
    <l-control
      :position="'bottomleft'"
    >
      <!-- <div>
        <img src="../../../../public/img/marker_blueBlack.png" style="width: 15px; height: 20px;"> : 시작 <br/>
        <img src="../../../../public/img/marker_red.png" style="width: 15px; height: 20px;"> : 현재
      </div> -->
    </l-control>

    <template v-for="(item, index) in markers">
      <v-rotated-marker v-if="item.markersIcon == 'MAX'"
                        :key="`marker-${index}`"
                        :lat-lng="item.markerLatLng"
                        :icon="marker_red"
                        :rotation-angle="item.rotationAngle"
                        :options="{opacity:item.opacity}"
      >
        <l-tooltip v-if="item.markersIcon == 'MAX'">
          Date/Time : {{ maxDtm ? maxDtm : '' }} (UTC)<br>
          <!-- Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
          dtm : {{ item.cntrDtm }} -->
        </l-tooltip>
      </v-rotated-marker>

      <v-rotated-marker v-if="item.markersIcon == 'MIN'"
                        :key="`marker-${index}`"
                        :lat-lng="item.markerLatLng"
                        :icon="marker_blueBlack"
                        :rotation-angle="item.rotationAngle"
                        :options="{opacity:item.opacity}"
      >
        <!-- <l-tooltip v-if="item.markersIcon == 'MIN'">
          Date/Time : {{ minDtm ? minDtm : '' }} <br>
          Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
          dtm : {{ item.cntrDtm }}
        </l-tooltip> -->
      </v-rotated-marker>

      <l-circle-marker v-else
                       :key="`cir-marker-${index}`"
                       :lat-lng="item.markerLatLng"
                       :radius="2.5"
                       :color="'#FFC31E'"
                       :fill-color="'#FFC31E'"
      >
        <!-- <l-tooltip>
          Date/Time : {{ maxDtm ? maxDtm : '' }} <br>
          Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
          dtm : {{ item.cntrDtm }}
        </l-tooltip> -->
      </l-circle-marker>
    </template>

    <template v-for="(item, index) in markers2">
      <v-rotated-marker v-if="item.markersIcon == 'MAX'"
                        :key="`marker2-${index}`"
                        :lat-lng="item.markerLatLng"
                        :rotation-angle="item.rotationAngle"
                        :icon="marker_red"
      >
        <l-tooltip v-if="item.markersIcon == 'MAX'">
          Date/Time : {{ maxDtm ? maxDtm : '' }} (UTC)<br>
          <!-- Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br />
          dtm : {{ item.cntrDtm }} -->
        </l-tooltip>
      </v-rotated-marker>

      <l-circle-marker v-else
                       :key="`cir-marker2-${index}`"
                       :lat-lng="item.markerLatLng"
                       :radius="2.5"
                       :color="'#FFC31E'"
                       :fill-color="'#FFC31E'"
      >
        <l-tooltip v-if="item.markersIcon == 'MAX'">
          Date/Time : {{ maxDtm ? maxDtm : '' }} (UTC)<br>
          <!-- Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br /> -->
          <!-- dtm : {{ item.cntrDtm }} -->
        </l-tooltip>
      </l-circle-marker>
    </template>

    <template v-for="(item, index) in markers3">
      <v-rotated-marker
        :key="`marker3-${index}`"
        :lat-lng="item.markerLatLng"
        :icon="iconP"
      >
      </v-rotated-marker>
    </template>

    <template v-for="(item, index) in markers4">
      <v-rotated-marker
        :key="`marker4-${index}`"
        :lat-lng="item.markerLatLng"
        :rotation-angle="item.rotationAngle"
        :icon="item.markersIcon == 'MAX' ? marker_red : iconK"
      >
        <l-tooltip v-if="item.markersIcon == 'MAX'">
          Date/Time : {{ maxDtm ? maxDtm : '' }} (UTC)<br>
          <!-- Location : {{ item.markerLatLng.lat }}, {{ item.markerLatLng.lng }} <br /> -->
          <!-- dtm : {{ item.cntrDtm }} -->
        </l-tooltip>
      </v-rotated-marker>
    </template>

    <l-polyline :lat-lngs="polylineN.latlngs"
                :color="polylineN.color"
                :dash-array="polylineN.dashArray"
                :opacity="polylineN.opacity"
                :weight="polylineN.weight"
    ></l-polyline>

    <l-polyline :lat-lngs="polylineP.latlngs"
                :color="polylineP.color"
                :dash-array="polylineP.dashArray"
                :opacity="polylineP.opacity"
                :weight="polylineP.weight"
    ></l-polyline>

    <l-polyline :lat-lngs="polyline.latlngs"
                :color="polyline.color"
                :dash-array="polyline.dashArray"
                :opacity="polyline.opacity"
                :weight="polyline.weight"
    >
    </l-polyline>
  </l-map>
</template>

<script>

import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import vslScheduleInfo2 from '@/api/rest/schedule/vslScheduleInfo2'
import { latLng, icon } from 'leaflet'
import { LMap, LTileLayer, LMarker, LControl, LTooltip, LPolyline, LCircleMarker } from 'vue2-leaflet'
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker'
import 'leaflet/dist/leaflet.css'
import rfDg from '@/api/rest/trans/rfDg'
import { rfDataGmt } from '@/api/rf-gmt-info'

export default {
  name: 'RfLocationInfo',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LTooltip,
    LPolyline,
    LCircleMarker,
    'v-rotated-marker': Vue2LeafletRotatedMarker
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
        }
      }
     }
  },
  data: function () {
    return {
      // 지도 확대정도
      zoom: 5,
      // 지도 중앙 위/경도(default - 서울)
      center: latLng(37.564214, 127.001699),
      // 지도 호출 url
      url: 'https://api.maptiler.com/maps/openstreetmap/256/{z}/{x}/{y}.jpg?key=DiDJ0YR81yQKSEioZPoD',
      // url: 'https://tiles.stadiamaps.com/tiles/outdoors/{z}/{x}/{y}{r}.png',
      // 우측 하단 표시
      attribution: '<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>',
      // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="https://openstreetmap.org">OpenStreetMap</a> contributors',
      // 아이콘 표시 위/경도(default - 서울)
      makerLatLng: latLng(37.564214, 127.001699),
      // 지도 옵션
      mapOptions: {
        zoomSnap: 0.5,
        minZoom: 2,
        maxZoom: 30
      },
      // 아이콘 옵션
      icon: icon({
        iconUrl: this.parentInfo.catCd === 'V' ? '/img/EKMTC_SHIP_2.png' : '/img/marker_red.gif',
        iconSize: this.parentInfo.catCd === 'V' ? [20, 20] : [30, 30],
        iconAnchor: [20, 15]
      }),
      // 선박 위치 수신일자
      vslUpdateTime: '',
      rotationAngle: 0,

      markers: [],
      markers2: [],
      markers3: [
        {
          type: 'P',
          markerLatLng: latLng(37.564214, 127.001699),
          ctrCd: 'N/A',
          plcCd: 'N/A',
          portNm: 'N/A'
        }
      ],
      markers4: [
        {
          type: 'P',
          markerLatLng: latLng(37.564214, 127.001699),
          ctrCd: 'N/A',
          plcCd: 'N/A',
          portNm: 'N/A'
        }
      ],
      focusVessel: [
        { name: '선명/항차', vslCd_voyNo: '' },
        { name: '선박 이름', vslNm: '' },
        { name: '선박 상태', nvgSts: '' },
        { name: 'AIS ETA', aisEta: '' },
        { name: 'AIS DEST', aisDest: '' },
        { name: 'SPEED', spd: '' },
        { name: '서비스 루트', rteCd: '' }
      ],
      // 아이콘 옵션
      iconP: icon({
        iconUrl: '/img/port3.png',
        iconSize: [12, 12]
      }),
      iconK: icon({
        iconUrl: '/img/blue_ship.png',
        iconSize: [15, 15]
      }),
      marker_red: icon({
        iconUrl: '/img/marker_red.png',
        iconSize: [25, 25]
      }),
      marker_blueBlack: icon({
        iconUrl: '/img/marker_blueblack.png',
        iconSize: [25, 25]
      }),

      polyline: {
        latlngs: [],
        color: 'purple',
        dashArray: '1, 5',
        opacity: 0.6,
        weight: 3,
        max: []
        // typ2: { latlngs: [], color: 'indigo', dashArray: '1, 5', opacity: 0.6, weight: 3, max: [] }
      },
      polylineP: {
        latlngs: [],
        color: 'purple',
        dashArray: '1, 5',
        opacity: 0.6,
        weight: 3
      },
      polylineN: {
        latlngs: [],
        color: 'gray',
        dashArray: '1, 5',
        opacity: 1,
        weight: 2
      },

      maxDtmData: [],
      maxDtm: '',
      minDtm: '',
      rank: '',
      step01: {}, // Empty Container Pick up
      step02: {}, // Port of Loading
      step03: {}, // On Board
      step04: {}, // Port of Discharging
      step05: {}, // Gate Out
      step06: {}, // Return,

      stLocation: {},
      ctrInfoList: [],
      loadMvntDtm: '',
      discMvntDtm: ''
    }
  },
  //실시간적으로 변하는 곳에 사용
  watch: {
    'parentInfo.cntrLoInfoList': {
      handler (val) {
        // console.log('child watch RF LOCATION @@@@', val)
        this.init()
      },
      deep: true
    }
  },
  async created () {
    this.ctrInfoList = await rfDataGmt.ctrInfoList

    if (this.$props.parentInfo.cntrLoInfoList.length > 0) {
      await this.init()
    }
    // console.log('created RF LOCATION @@@@', this.$props.parentInfo)
  },
  mounted () {
  },
  methods: {
    async init () {
      const today = moment().format('YYYYMMDDHHmmss')
      let loadMvntDtm = ''
      let discMvntDtm = ''

      this.rank = this.$props.parentInfo.trackingSteps.rank
      this.step01 = this.$props.parentInfo.trackingSteps.step01 // Empty Container Pick up
      this.step02 = this.$props.parentInfo.trackingSteps.step02 // Port of Loading
      this.step03 = this.$props.parentInfo.trackingSteps.step03 // On Board
      this.step04 = this.$props.parentInfo.trackingSteps.step04 // Port of Discharging
      this.step05 = this.$props.parentInfo.trackingSteps.step05 // Gate Out
      this.step06 = this.$props.parentInfo.trackingSteps.step06 // Return
      this.step99 = this.$props.parentInfo.trackingSteps.step99

      // loading 했을때
      if (this.$props.parentInfo.movementGmt.step03MvntDtm !== '') {
        // loadMvntDtm = this.step03.mvntDt + this.step03.mvntTm
        this.loadMvntDtm = this.$props.parentInfo.movementGmt.step03MvntDtm
      } else {
        this.loadMvntDtm = today
      }

      // Discharging 했을때,
      if (this.$props.parentInfo.movementGmt.step04MvntDtm !== '') {
        // discMvntDtm = this.step04.mvntDt + this.step04.mvntTm
        this.discMvntDtm = this.$props.parentInfo.movementGmt.step04MvntDtm
      } else if (this.$props.parentInfo.movementGmt.step99MvntDtm !== '') {
        // discMvntDtm = this.step99.mvntDt + this.step99.mvntTm
        this.discMvntDtm = this.$props.parentInfo.movementGmt.step099MvntDtm
      }

      // 선박 실시간 위치 지도에 표시
      await this.getLocationToMapInit()
    },
    async getPolyline () {
      var stLocation = []
      var laLocation = []

      this.polyline.latlngs = []
      this.polyline.max = []

      this.polylineP.latlngs = []
      this.polylineP.max = []

      // loading 전에
      for (const element of this.markers) {
        stLocation.push([element.cntrDtm, element.markerLatLng])
      }

      stLocation.sort(function (first, second) {
          return second[8] - first[8]
      })

      for (let i = 0; i < stLocation.length; i++) {
        let calStLocation
        if (stLocation[i][0].indexOf('T') > -1) {
          calStLocation = ((stLocation[i][0].substring(0, 10) + stLocation[i][0].substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')
        } else {
          calStLocation = stLocation[i][0]
        }

        this.polyline.latlngs.push([stLocation[i][1].lat, stLocation[i][1].lng])
      }

      // discharging 후에
      laLocation.sort(function (first, second) {
          return second[8] - first[8]
      })

      for (const element of this.markers2) {
        laLocation.push([element.cntrDtm, element.markerLatLng])
      }

      for (let i = 0; i < laLocation.length; i++) {
        let calLaLocation
        if (laLocation[i][0].indexOf('T') > -1) {
          calLaLocation = ((laLocation[i][0].substring(0, 10) + laLocation[i][0].substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')
        } else {
          calLaLocation = laLocation[i][0]
        }

        this.polylineP.latlngs.push([laLocation[i][1].lat, laLocation[i][1].lng])
      }
    },
    async getLocationToMapInit () {
      this.maxDtmData = []
      let maxDtm = ''
      // IOT 최신 데이터 위치
      if (this.$ekmtcCommon.isNotEmpty(this.$props.parentInfo.maxDtm)) {
        maxDtm = ((this.$props.parentInfo.maxDtm.substring(0, 10) + this.$props.parentInfo.maxDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')
      }
      this.maxDtmData.push(maxDtm)

      // console.log('getLocationToMapInit @@@@@ ')

      let data = this.$props.parentInfo
      let latLngInfo = data.cntrLoInfoList

      this.markers = []
      this.markers2 = []

      const today = moment().format('YYYYMMDDHHmmss')
      let loadMvntDtm = ''
      let cntrDtm = ''
      let discMvntDtm = ''
      let emptyCntrDtm = ''
      let convCntrDtm = ''
      let scheEta = ''
      let stDtm = ''
      let len = latLngInfo.length

       await latLngInfo.forEach((item, index) => {
          cntrDtm = ((item.cntrDtm.substring(0, 10) + item.cntrDtm.substring(11, 19)).replaceAll('-', '')).replaceAll(':', '')

          if (this.parentInfo.vslLocation.length > 0) {
            scheEta = this.parentInfo.vslLocation[0].etd + '00'
          }

          // on board 했을때, IOT값 중에서 loading한 시간보다 작은 값만 넣음
          if (this.loadMvntDtm !== '') {
            if (Number(cntrDtm) <= Number(this.loadMvntDtm) &&
                Number(item.lttd) !== 0 && Number(item.lngtd) !== 0) {
                this.markers.push({
                  opacity: 1.0,
                  markerLatLng: latLng(item.lttd, item.lngtd),
                  rotationAngle: 0,
                  vslNm: 'N/A',
                  vslCd: 'N/A',
                  voyNo: 'N/A',
                  rteCd: 'N/A',
                  cntrDtm: cntrDtm.replaceAll('-', '').replaceAll(':', ''),
                  markersIcon: ''
                })
            }

            // 시작위치 로컬 타임 변환값 넣어줌
            this.markers.forEach((item, idx) => {
              if (idx === (this.markers.length - 1)) {
                this.stLocation.cntrDtm = item.cntrDtm
              }
            })
          }

          if (this.discMvntDtm !== '') {
            // Discharging 했을때, IOT값 중에서 Discharging한 시간보다 큰 값만 넣음
            if (Number(this.discMvntDtm) <= Number(cntrDtm) && Number(item.lttd) !== 0 && Number(item.lngtd) !== 0) {
                this.markers2.push({
                  opacity: 1.0,
                  markerLatLng: latLng(item.lttd, item.lngtd),
                  rotationAngle: 0,
                  vslNm: 'N/A',
                  vslCd: 'N/A',
                  voyNo: 'N/A',
                  rteCd: 'N/A',
                  cntrDtm: cntrDtm.replaceAll('-', '').replaceAll(':', ''),
                  markersIcon: ''
                })
              }
          }
        })

        // 위치 점선 표시
        await this.getPolyline()
        await this.getAisVslLocationData()
    },
    async getAisVslLocationData () {
        console.log('@@@@@ getAisVslLocationData @@@@@ ')

        let vslLocation = []
        let preVslData
        let data = {
          vslCd: '',
          rteCd: '',
          voyNo: '',
          portCd: '',
          drctn: ''
        }

        if (this.parentInfo.vslLocation.length > 0) {
          vslLocation = this.parentInfo.vslLocation
          preVslData = vslLocation[vslLocation.length - 1]
        }

        const vm = this
        this.markers3 = []
        this.markers4 = []

        for (let item of vslLocation) {
          let paramEta = ''
          let paramEtd = ''
          let etaMvntDt = ''
          let etaMvntTm = ''
          let etaDtm = ''

          let etdMvntDt = ''
          let etdMvntTm = ''
          let etdDtm = ''
          let eta = ''
          let etd = ''

          if (item.etd === '000000000000') {
            paramEtd = this.convertDtm(this.step03.mvntDt, this.step03.mvntTm)
            paramEtd = await this.localToGmt(paramEtd, 'L')
          } else {
            paramEtd = item.etd
            // etdMvntDt = moment(item.etd.substring(0, 8)).format('YYYY-MM-DD')
            // etdMvntTm = item.etd.substring(8, 10) + ':' + item.etd.substring(10, 12) + ':' + '00'
            // etdDtm = etdMvntDt + ' ' + etdMvntTm

            // etd = new Date(etdDtm)
            // etd = etd.getTime() + -(16 * 60 * 60 * 1000)
            // etd = new Date(etd)
            // etd = moment(etd).format('YYYYMMDDHHMMSS')
          }

          if (item.eta === '000000000000') {
            paramEta = this.convertDtm(this.step04.mvntDt, this.step04.mvntTm)
            paramEta = await this.localToGmt(paramEta, 'D')
          } else {
            paramEta = item.eta
            // etaMvntDt = moment(item.eta.substring(0, 8)).format('YYYY-MM-DD')
            // etaMvntTm = item.eta.substring(8, 10) + ':' + item.eta.substring(10, 12) + ':' + '00'
            // etaDtm = etaMvntDt + ' ' + etaMvntTm

            // eta = new Date(etaDtm)
            // eta = eta.getTime() + (16 * 60 * 60 * 1000)
            // eta = new Date(eta)
            // paramEta = moment(eta).format('YYYYMMDDHHMMSS')
          }

          data = {
            vslCd: item.vslCd,
            rteCd: item.rteCd,
            voyNo: item.voyNo,
            drctn: ['E', 'W', 'N', 'S'].includes(item.voyNo.slice(-1)) ? item.voyNo.slice(-1) : preVslData.voyNo.slice(0, 1),
            eta: paramEta,
            etd: paramEtd
          }

          await rfDg.getVesselPreiousRoute(data)
            .then(response => {
              // console.log('getVesselPreiousRoute response @@@@@ ', response)

              if (response.status === 200) {
                  const resData = response.data
                  const VesselPreiousRoute = resData.vesselPreiousRoute

                  var tmp = 'N/A'
                  VesselPreiousRoute.forEach(item => {
                    for (let i = 191; i <= vm.markers3.length - 1; i++) {
                      var ais_dest = item.aisDest

                      // ais_dest = ais_dest.replace(/ /g, '')
                      // ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
                      // // console.log('ais_dest @@@@@ ', ais_dest)
                      // if (ais_dest === vm.markers3[i].ctrCd) {
                      //   tmp = vm.markers3[i].plcCd
                      //   item.aisDest = tmp
                      // }
                      if (ais_dest.indexOf('OFF') === -1) {
                        if (ais_dest.indexOf('_') > -1) {
                          ais_dest = ais_dest.replaceAll('_', ' ').substring(3, 6)
                        } else if (ais_dest.indexOf('-') > -1) {
                          ais_dest = ais_dest.replaceAll('-', '')
                        } else {
                          if (ais_dest.indexOf('PUS') > -1) { // 부산 포트이름 특이케이스
                            ais_dest = 'PUS'
                          } else {
                            ais_dest = ais_dest.substring(ais_dest.indexOf('>') + 1, ais_dest.length + 1)
                          }
                        }

                        if (ais_dest === this.markers3[i].ctrCd) {
                          tmp = this.markers3[i].plcCd
                          item.aisDest = tmp
                        }
                      }
                    }

                      // AIS,ICC port Cd 데이터 다름
                      if (item.aisDest === 'PKG') {
                        item.aisDest = 'PKW'
                      }
                  })
                  VesselPreiousRoute.forEach((item, idx) => {
                    const lttd = Number(item.lttd)
                    const lngtd = Number(item.lngtd)
                    let convAisRecvDtm = ''

                    convAisRecvDtm = this.convertDtm(item.aisRecvDtm.substring(0, 8), item.aisRecvDtm.substring(8))

                    let date = new Date(convAisRecvDtm)
                    const utcNow = date.getTime() + (-(9 * 60) * 60 * 1000) // 현재 시간을 utc로 변환한 밀리세컨드값
                    const koreaTimeDiff = 9 * 60 * 60 * 1000 // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
                    date = new Date(utcNow)

                    // 날짜/시간 비교위해 포맷 변환
                    convAisRecvDtm = this.convertDate(date)

                    vm.maxDtmData.push(convAisRecvDtm)

                    // AIS 위치 정렬
                    vm.maxDtmData.sort((a, b) => {
                      return Number(b) - Number(a)
                    })

                    if (vm.rank > 4 && vm.step03 !== undefined && Object.keys(vm.step03).length > 0) {
                      vm.markers4.push({
                        opacity: 1.0,
                        markerLatLng: latLng(lttd, lngtd),
                        rotationAngle: Number(item.prsPrgAng),
                        vslNm: 'N/A',
                        vslCd: 'N/A',
                        voyNo: 'N/A',
                        rteCd: 'N/A',
                        cntrDtm: convAisRecvDtm,
                        markersIcon: ''
                      })
                    }
                  })
                }

                this.maxDtm = ''
                this.minDtm = ''

                // 가장 최근 데이터 위치 찾기
                this.markers.forEach(mr => {
                  if (mr.cntrDtm === this.maxDtmData[0]) {
                    mr.markersIcon = 'MAX'

                    this.center = mr.markerLatLng

                    if (mr.cntrDtm.indexOf('T') > -1) {
                       this.maxDtm = (mr.cntrDtm.substring(0, 10) + ' ' + mr.cntrDtm.substring(11, 16))
                    } else {
                       this.maxDtm = (mr.cntrDtm.substring(0, 4) + '-' +
                                      mr.cntrDtm.substring(4, 6) + '-' + mr.cntrDtm.substring(6, 8) + ' ' +
                                      mr.cntrDtm.substring(8, 10) + ':' + mr.cntrDtm.substring(10, 12))
                    }
                  } else if (mr.cntrDtm === this.stLocation.cntrDtm) { // 시작 위치
                    mr.markersIcon = 'MIN'
                    this.minDtm = this.stLocation.cntrDtm
                  } else {
                    mr.markersIcon = 'OTH'
                  }
                })

                this.markers2.forEach(mr => {
                  if (mr.cntrDtm === this.maxDtmData[0]) {
                    mr.markersIcon = 'MAX'

                    this.center = mr.markerLatLng
                    if (mr.cntrDtm.indexOf('T') > -1) {
                        this.maxDtm = (mr.cntrDtm.substring(0, 10) + ' ' + mr.cntrDtm.substring(11, 16))
                    } else {
                      this.maxDtm = (mr.cntrDtm.substring(0, 4) + '-' +
                        mr.cntrDtm.substring(4, 6) + '-' + mr.cntrDtm.substring(6, 8) + ' ' +
                        mr.cntrDtm.substring(8, 10) + ':' + mr.cntrDtm.substring(10, 12))
                    }
                  } else {
                    mr.markersIcon = 'OTH'
                  }
                })

                this.markers4.forEach(mr4 => {
                  if (mr4.cntrDtm === this.maxDtmData[0]) {
                    mr4.markersIcon = 'MAX'
                    mr4.rotationAngle = 0

                    this.center = mr4.markerLatLng
                    this.maxDtm = (this.maxDtmData[0].substring(0, 4) + '-' +
                                  this.maxDtmData[0].substring(4, 6) + '-' + this.maxDtmData[0].substring(6, 8) + ' ' +
                               this.maxDtmData[0].substring(8, 10) + ':' + this.maxDtmData[0].substring(10, 12))
                  } else {
                    mr4.markersIcon = 'OTH'
                  }
                })

                // MAX값이 null 일때 처리
                if (this.maxDtm === '') {
                  var allMarker = [...this.markers, ...this.markers2, ...this.markers4]

                  if (allMarker.length > 0) {
                    allMarker.sort((a, b) => {
                      return Number(b.cntrDtm) - Number(a.cntrDtm)
                    })

                    let maxIdx = (allMarker.length - 1)

                    allMarker[0].markersIcon = 'MAX'
                    allMarker[0].rotationAngle = 0

                    this.center = allMarker[0].markerLatLng

                    this.maxDtm = (allMarker[0].cntrDtm.substring(0, 4) + '-' +
                                    allMarker[0].cntrDtm.substring(4, 6) + '-' + allMarker[0].cntrDtm.substring(6, 8) + ' ' +
                                allMarker[0].cntrDtm.substring(8, 10) + ':' + allMarker[0].cntrDtm.substring(10, 12))
                  }

                  // console.log('allMarker @@@@ ', allMarker)
                }
            })
            .catch(error => {
              console.log(error)
            })
        }

        // IOT 가장 최근 위치
        // console.log('maxDtm @@@@ ', maxDtm)
    },
    async localToGmt (time, kind) {
      let date = new Date(time)
      let utcNow // 현재 시간을 utc로 변환한 밀리세컨드값
      let timeDiff = 9 * 60 * 60 * 1000 // 한국 시간은 UTC보다 9시간 빠름(9시간의 밀리세컨드 표현)
      let getTimezoneOffset

      let convCntrDtm = ''
      let polCtrCd = this.parentInfo.polCtrCd
      let podCtrCd = this.parentInfo.podCtrCd
      let polCtrGmt = ''
      let podCtrGmt = ''

      for (let gmtDiff of this.ctrInfoList) {
        if (gmtDiff.keyNm === polCtrCd) {
          polCtrGmt = Number(gmtDiff.info)
        }

        if (gmtDiff.keyNm === podCtrCd) {
          podCtrGmt = Number(gmtDiff.info)
        }
      }

      if (kind === 'L') {
        getTimezoneOffset = -(polCtrGmt * 60)
      }

      if (kind === 'D') {
        getTimezoneOffset = -(podCtrGmt * 60)
      }

      utcNow = date.getTime() + (getTimezoneOffset * 60 * 1000)
      date = new Date(utcNow)

      // 날짜/시간 비교위해 포맷 변환
      convCntrDtm = await this.convertDate(date)

      return convCntrDtm
    },
    convertDate (date) {
      var year = date.getFullYear()
      var month = ('0' + (date.getMonth() + 1)).slice(-2)
      var day = ('0' + date.getDate()).slice(-2)
      var hours = ('0' + date.getHours()).slice(-2)
      var minutes = ('0' + date.getMinutes()).slice(-2)
      var seconds = ('0' + date.getSeconds()).slice(-2)

      var timeString = hours + ':' + minutes + ':' + seconds
      var dateString = year + '-' + month + '-' + day

      return ((dateString + timeString).replaceAll('-', '')).replaceAll(':', '')
    },
    convertDtm (dt, tm) {
      let mvntDtm
      let mvntDt = moment(dt).format('YYYY-MM-DD')
      let mvntTm = tm.substring(0, 2) + ':' + tm.substring(2, 4) + ':' + tm.substring(4)

      mvntDtm = mvntDt + ' ' + mvntTm

      return mvntDtm
    }
  }
}
</script>
